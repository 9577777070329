import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { changeHeaderName, getAllServiceProviders, requestBusinessStats } from '../../actions/dashboardActions';
import ServiceProviderTable from './ServiceProviderTable';

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    }
});

class ServiceProvider extends Component {
    constructor(props) {
        super(props);
        this.state = JSON.parse(window.localStorage.getItem('serviceProviderDetails')) || {
            order: -1,
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 10,
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            firstName: "",
            lastName: "",
            email: "",
            primaryMobileNumber: "",
            ranges: { },
            preparexportToExcel: false,
        };
    }

    componentDidMount() {
        this.props.changeHeaderName("Service Providers");
        this.fetchServiceProvidersByQuery();
        window.addEventListener("beforeunload", () => localStorage.removeItem('serviceProviderDetails'));
    }

    fetchServiceProvidersByQuery = async () => {
        this.props.requestBusinessStats();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            email: this.state.email.trim(),
            primaryMobileNumber: this.state.primaryMobileNumber.trim(),
            exportToexcel: 0,

        }
        await this.props.getAllServiceProviders(payload);
        if (this.state.preparexportToExcel) {
            await this.setState({ preparexportToExcel: false });
        }
        localStorage.setItem("serviceProviderDetails", JSON.stringify(this.state));
    }

    fetchServiceProvidersByQueryExcelFile = async () => {
        this.props.requestBusinessStats();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            email: this.state.email.trim(),
            primaryMobileNumber: this.state.primaryMobileNumber.trim(),
            exportToexcel: 1,

        }
        await this.props.getAllServiceProviders(payload);
        await this.setState({ preparexportToExcel: true });

    }

    setOrder = (order) => {
        this.setState({ order }, () => this.fetchServiceProvidersByQuery());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchServiceProvidersByQuery());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchServiceProvidersByQuery());
    }
    
    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'creationDate',
            page: 0,
            rowsPerPage: 10,
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            firstName: "",
            lastName: "",
            email: "",
            primaryMobileNumber: ""
        }, () => {
            this.fetchServiceProvidersByQuery();
        })
    }

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        // picker.endDate.add(1, "days");
        this.setState({
            // filterStartDate: picker.startDate.format('DD MMM YYYY'),
            // filterEndDate: picker.endDate.format('DD MMM YYYY')
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    }

    enterPressed = (event) => {
        let code = event.keyCode;
        if (code === 13) {
            event.preventDefault();
            this.fetchServiceProvidersByQuery();
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Service Providers</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="firstName" label="First Name" value={this.state.firstName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="lastName" label="Last Name" value={this.state.lastName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="primaryMobileNumber" label="Mobile Number" value={this.state.primaryMobileNumber} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="email" label="Email Id" value={this.state.email} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={{
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

                                        }}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="createdAt" label="Registration Date Range" value={this.state.dateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                <Grid item xs={5}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={
                                            ()=>this.setPage(0)
                                           // this.fetchServiceProvidersByQuery
                                        }
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.container}>
                    {
                        this.props.serviceProvidersList && this.props.serviceProvidersList.data ?
                            <ServiceProviderTable
                                data={this.props.serviceProvidersList}
                                exportToexcel={this.props.serviceProvidersList.exportToexcel}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                currentStatus={this.state.currentStatus}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                handleFilterChange={this.handleFilterChange}
                                history={this.props.history}
                                fetchServiceProvidersByQueryExcelFile={this.fetchServiceProvidersByQueryExcelFile}
                                showDownloadIcon={this.state.preparexportToExcel}
                            />
                            : null
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.dashboard.isFetching,
        serviceProvidersList: state.dashboard.serviceProvidersList
    }
};

export default connect(mapStateToProps, { changeHeaderName, getAllServiceProviders, requestBusinessStats })(withStyles(styles)(ServiceProvider));