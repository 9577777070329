import axios from "axios";
import {
  SET_PAYMENT_SUMMARY_ERROR_MESSAGE,
  SET_PAYMENT_SUMMARY,
  REQUEST_PAYMENT_SUMMARY,
  SET_PAYMENT_SUMMARY_EXCEL,
  SET_PAYMENT_SUMMARY_CASHFREE_SETTLEMENT_DATA,
  CHANGE_AP_ORDERS_SUCCESS_MESSAGE,
  CHANGE_AP_ORDERS_ERROR_MESSAGE,
  SET_TR_NOTE_SUCCESS_MESSAGE,
  SET_TR_NOTE_ERROR_MESSAGE
} from "./types";

export const clearMsg = () => async dispatch => {   
 let data = null;  
  dispatch({ type: CHANGE_AP_ORDERS_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: CHANGE_AP_ORDERS_ERROR_MESSAGE, payload: data });
  
};
export const requestPaymentSummary = () => {
  return {
    type: REQUEST_PAYMENT_SUMMARY,
    payload: true,
  };
};

export const getPaymentSummary = (payload) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_PAYMENT_SUMMARY, payload: true });
    let response = await axios.get(`api/pgIntegration/getallpaymentsdone`, {
      params: payload,
    });
    dispatch({
      type: SET_PAYMENT_SUMMARY,
      payload: response.data,
    });
    dispatch({ type: REQUEST_PAYMENT_SUMMARY, payload: false });
  } catch (err) {
    console.log("Transaction Report Error==========>", err);
    dispatch({
      type: SET_PAYMENT_SUMMARY_ERROR_MESSAGE,
      payload: { ops: "UPDATE", msg: "Error while fetching Transaction Report." },
    });
    dispatch({ type: REQUEST_PAYMENT_SUMMARY, payload: false });
  }
};

export const getPaymentSummaryExcel = (payload) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_PAYMENT_SUMMARY, payload: true });
    let response = await axios.get(`api/pgIntegration/exportAllPaymentsDone`, {
      params: payload,
    });
    dispatch({
      type: SET_PAYMENT_SUMMARY_EXCEL,
      payload: response.data,
    });
    dispatch({ type: REQUEST_PAYMENT_SUMMARY, payload: false });
  } catch (err) {
    console.log("Transaction Report Export Error==========>", err);
    dispatch({
      type: SET_PAYMENT_SUMMARY_ERROR_MESSAGE,
      payload: { ops: "UPDATE", msg: "Error while fetching Transaction Report Export." },
    });
    dispatch({ type: REQUEST_PAYMENT_SUMMARY, payload: false });
  }
};

export const getCashFreeSettlementData = (payload) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_PAYMENT_SUMMARY, payload: true });
    let response = await axios.get(`api/pgIntegration/getCashFreeSettlementData`, {
      params: payload,
    });
    dispatch({
      type: SET_PAYMENT_SUMMARY_CASHFREE_SETTLEMENT_DATA,
      payload: response.data,
    });
    dispatch({ type: REQUEST_PAYMENT_SUMMARY, payload: false });
  } catch (err) {
    console.log("Transaction Report getCashFreeSettlementData Error==========>", err);
    dispatch({
      type: SET_PAYMENT_SUMMARY_ERROR_MESSAGE,
      payload: { ops: "UPDATE", msg: "Error while fetching Transaction Report getCashFreeSettlementData." },
    });
    dispatch({ type: REQUEST_PAYMENT_SUMMARY, payload: false });
  }
};
export const changeAPStatus = (payload) => async (dispatch) => {
	try { 
	  let response = await axios.patch(`api/pgIntegration/changeAPStatus`, payload);   
      dispatch({ type: CHANGE_AP_ORDERS_SUCCESS_MESSAGE, payload: response.data.message  });
	} catch (error) {
    dispatch({ type: CHANGE_AP_ORDERS_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Failed to update businees." } });	  
	}
  };

export const addTRNotes = (payload) => async dispatch => {
  try {
      await axios.patch(`/api/pgIntegration/${payload._id}`, payload);
      dispatch({ type: SET_TR_NOTE_SUCCESS_MESSAGE, payload: { ops: "UPDATE" } });
  } catch (err) {
      dispatch({ type: SET_TR_NOTE_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Failed to update TR Notes" } });
  }
};
