import {
    SET_SERVICE_REQUESTS,
    SET_SR_SUCCESS_MESSAGE,
    SET_SR_ERROR_MESSAGE,
    SET_SR_PAYMENT_SUCCESS_MESSAGE,
	SET_SR_PAYMENT_ERROR_MESSAGE,
    SET_SR_DETAILS,
    REQUEST_SERVICE_REQUESTS,
    SR_EXPORTTOEXCEL,
    GET_REVIEW_REPORT,
    SET_SR_PAYMENT_IMAGE_SUCCESS_MESSAGE,
    ADD_SERVICE_REQUESTS_CONVERSATION,
    ADD_SERVICE_REQUESTS_XIRIFYADJUSTMENT,
    CREATE_DUNZOID_SUCCESS_MESSAGE,
    CREATE_DUNZOID_ERROR_MESSAGE,
    CREATE_DUNZO_ID,
    CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE,
	CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE_ERROR_MESSAGE,
    FIND_ALL_CONVERSATIONS
} from "../actions/types";

const INITIAL_STATE = {
    serviceRequests: {},
    serviceRequestDetails: {},
    sucessMsg: null,
    errorMsg: null,
    ops: "",
    isFetching: false,
    statusChangeSuccess: null,
    statusChangeErr: null,
    paymentStatusChangeSuccess: null,
    paymentStatusChangeErr: null,
    exportToExcel:[],
    adjustment:[],
    cancelMsg: null,
    cancelErrorMsg: null,
    allConversations:[]
};


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REQUEST_SERVICE_REQUESTS:
            return { ...state, isFetching: action.payload };
        case CREATE_DUNZO_ID:
            return { ...state, isFetching: action.payload };
        case SET_SERVICE_REQUESTS:
            console.log("action.pay",action.payload);

            return { ...state, serviceRequests: action.payload, isFetching: false };
        case SET_SR_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                isFetching: false 
            };
        case SET_SR_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                isFetching: false 
            };
        case SET_SR_PAYMENT_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                isFetching: false 
            }
        case SET_SR_PAYMENT_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                isFetching: false 
            }
        case SET_SR_DETAILS:
            return {
                ...state,
                serviceRequestDetails: action.payload,
                isFetching: false
            };
        case SR_EXPORTTOEXCEL:
            return {
                ...state,
                exportToExcel: action.payload,
                isFetching: false
            };    
        case GET_REVIEW_REPORT:
            return { ...state, reviewReport: action.payload, isFetching: false  };
        
        // case SET_SR_PAYMENT_IMAGE_SUCCESS_MESSAGE:
        //     return {
        //         ...state, imagefile: action.payload, isFetching: false
        //     }

        case CREATE_DUNZOID_SUCCESS_MESSAGE:
                return {...state,errorMsg: action.payload.msg, isFetching: false };
        case CREATE_DUNZOID_ERROR_MESSAGE:
               return {...state,adjustment:action.payload,isFetching: false}
        case CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE: {
            console.log(action)
            return {...state, cancelMsg: action.payload, isFetching: false };

        }                
        case CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE_ERROR_MESSAGE:
            return {...state, cancelErrorMsg:action.payload, isFetching: false}
        case FIND_ALL_CONVERSATIONS:
            // console.log("action.paylodddddddddd",action.payload);
            return {...state ,allConversations:action.payload,isFetching:false}
        default:
            return state;
       
            
    }
}