import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../utils/TabPanel';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import axios from "axios";
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import {
    deleteSubcategoryButton
} from '../../../actions/businessActions';
import ContractForm from './ContractForm';
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Input } from '@material-ui/core';
import { showPendingBusinessDetails } from "../../../actions/dashboardActions";
import {
    approveBusiness, rejectBusiness, getPanServPro, getAadharServPro, getShopActServPro,
    clearBusiSucessMsg, clearBusiErrorMsg, getMembersList, updateDeliveryPartnerAvailability, clearAadharCardMsg, clearPanCardMsg, updateAadharCard, updatePanCard, updateBusinessPersonalDetails, getBusinessById,deleteAllServices,getServicesByBusinessId,getHeadByBusinessId,getHeadSubheadHierarchy ,getHeadWithServicesByBusinessId
} from "../../../actions/businessActions";
import { 
    getAllVariationGroups,
  } from "../../../actions/variationsAction"
  import { getAllAddonList,getAllItemsLinkedWithAddons } from "../../../actions/addonsAction";
import { addContract, createBusinessAgreementNewContract } from "../../../actions/contractActions";
import ServiceSection from "../ApproveRejectBusiness/ServiceSection";
import MembersTable from '../ApproveRejectBusiness/MembersTable';
import BusinessDetailsSection from '../ApproveRejectBusiness/BusinessDetailSection';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { fetchEnvs } from '../../../actions/authActions';
import BusinessAgreementForm from '../ApproveRejectBusiness/BusinessAgreementForm';
import _ from 'lodash';
import { getAllAddOnGroups,} from '../../../actions/addonGroupAction'

const styles = theme => ({
    content: {
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        // padding : '10px',
        // width : '1260px',
        maxWidth: "1300px",
        position: "absolute",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    rejectBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50px',
        padding: '5px',
        backgroundColor: 'red',
        color: 'white'
    },
    img: {
        margin: '20px 0px',
    },
    modalContainer: {
        width: '40%',
        height: '90%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',
    },
    contractModalContainer: {
        width: '40%',
        height: '95%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    card: {
        minWidth: 175,
        width: 175,
        height: 100,
    },
    searchPaper: {
        padding: "0px 0px",
        display: "flex",
        alignItems: "center",
        marginBottom: "25px",
    },
    clearButton: {
        marginLeft: "25px",
    },
    modalBusinessAgreement: {
        width: "67%",
        height: "95%",
        backgroundColor: theme.palette.background.paper,
        padding: "0px 0px",
        display: "flex",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px",
    },
});

class BasicBusinessDetails extends Component {

    constructor() {
        super();
        this.state = {
            value: 0,
            snackOpen: false,
            backDropOpen: false,
            buttonDisabled: false,
            acceptDialogOpen: false,
            rejectDialogOpen: false,
            contractModalOpen: false,
            order: -1,
            orderBy: "name",
            page: 0,
            rowsPerPage: 10,
            searchValue: "",
            clearSearch: false,
            isDeliveryByPartnerAllowd: false,
            isDeliveryByPartner: false,
            showDownloadIcon: false,
            imgfileForAadhar: {},
            imgfileForPan: {},
            editDetails: false,
            requiredError: false,
            firstName: "",
            lastName: "",
            aadhar: "",
            pan: "",
            primaryMobileNumber: "",
            mobileNumbers: "",
            address: "",
            aadharCardNumber: "",
            panCardNumber: "",
            email: "",
            fileNameForAadhar: "",
            fileNameForPan: "",
            phoneValidationError: false,
            aadharValidationError: false,
            panValidationError: false,
            businessDashboardCards: {
                orders: true,
                cancel: true,
                shoppers: true,
                settlement: true,
                onlinePayment: true,
                directPayment: true,
                campaign: true,
                userSentiment: true,
            },
            specialityListArr: [],
            cuisineListArr: [],
            resetButtonDisabled : false,
            createContractFlag: false, // for new business agreement
            initialData: {}, // for new business agreement
            mobileValidationError : false,
            mobileValidationSpaceError : false
        };
    }

    // componentWillMount() {
    //   this.setData();
    //   this.props.clearBusiErrorMsg();
    //   this.props.clearBusiSucessMsg();
    //   this.props.getPanServPro(this.props.dashboard.setSelectedBusiness.ownerId._id)
    //   this.props.getAadharServPro(this.props.dashboard.setSelectedBusiness.ownerId._id)
    //   this.props.getShopActServPro({
    //     serviceProviderId: this.props.dashboard.setSelectedBusiness.ownerId._id,
    //     businessId: this.props.dashboard.setSelectedBusiness._id
    //   });

    //   if(this.props.user.role !== "areaManager"){
    //     this.setState({
    //       buttonDisabled: true
    //     });
    //   }
    // }

    handleChangeTab = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleBusinessCardEnableChange = async (e, cardName) => {
        let newBusinessDashboardCards = { ...this.state.businessDashboardCards };
        newBusinessDashboardCards[cardName] = e.target.checked;


        await axios.patch("/api/business/" + this.props.dashboard.setSelectedBusiness._id, { businessDashboardCards: newBusinessDashboardCards });
        this.setState({ businessDashboardCards: newBusinessDashboardCards });

    }

    setData = () => {
        this.setState({
            isDeliveryByPartnerAllowd: this.props.dashboard.setSelectedBusiness.isDeliveryPartnerAllowed,
            isDeliveryByPartner: this.props.dashboard.setSelectedBusiness.isDeliveryByPartner,
            firstName: this.props.dashboard.setSelectedBusiness.ownerId.firstName,
            lastName: this.props.dashboard.setSelectedBusiness.ownerId.lastName,
            email: this.props.dashboard.setSelectedBusiness.ownerId.email,
            primaryMobileNumber: this.props.dashboard.setSelectedBusiness.ownerId.primaryMobileNumber,
            mobileNumbers: this.props.dashboard.setSelectedBusiness.ownerId.mobileNumbers,
            address: this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.address,
            aadharCardNumber: this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.aadhar.number,
            panCardNumber: this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.panCard.number,
            imgfileForAadhar: this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.aadhar,
            businessDashboardCards: this.props.dashboard.setSelectedBusiness.businessDashboardCards
        })
    }

    componentDidMount() {
        console.log(" this.props in BBD --->", this.props)
        this.setData();
        this.props.fetchEnvs();


        this.getMembersList();

        this.props.clearBusiErrorMsg();
        this.props.clearBusiSucessMsg();
        this.props.clearAadharCardMsg();
        this.props.clearPanCardMsg();
        this.props.getPanServPro(this.props.dashboard.setSelectedBusiness.ownerId._id);
        this.props.getAadharServPro(this.props.dashboard.setSelectedBusiness.ownerId._id);
        this.props.getShopActServPro({
            serviceProviderId: this.props.dashboard.setSelectedBusiness.ownerId._id,
            businessId: this.props.dashboard.setSelectedBusiness._id
        });

        if (this.props.user.role !== "areaManager") {
            this.setState({
                buttonDisabled: true
            });
        }
        if (this.props.user.role === "areaManager") {
            this.setState({
                resetButtonDisabled: false
            });
        }
        if(this.props.user.role === "admin" || this.props.user.role === "regionalHead") {
            this.setState({
                resetButtonDisabled: true
            });
        }
        axios('/api/specialities').then((res)=> {
            this.setState({ specialityListArr: res.data });
        });

        axios('/api/cuisines').then((res)=> {
            this.setState({ cuisineListArr: res.data });
        });;
    }
    handleChange = (event) => {
        if (event.target.name === "firstName") { this.setState({ [event.target.name]: event.target.value, error: {}, requiredError: false }); }
        if (event.target.name === "lastName") { this.setState({ [event.target.name]: event.target.value, error: {}, requiredError: false }); }
        if (event.target.name === "primaryMobileNumber") { this.setState({ [event.target.name]: event.target.value, error: {}, phoneValidationError: false }); }
        if (event.target.name === "aadharCardNumber") { this.setState({ [event.target.name]: event.target.value, error: {}, aadharValidationError: false }); }
        if (event.target.name === "panCardNumber") { this.setState({ [event.target.name]: event.target.value, error: {}, panValidationError: false }); }
        if (event.target.name === "mobileNumbers") { this.setState({ [event.target.name]: event.target.value, error: {}, mobileValidationError: false,mobileValidationSpaceError : false }); }

        this.setState({
            [event.target.name]: event.target.value,
            // error: {},

            businessVideoError: false,
            businessVideoErrorExists: false,
            businessVideoErrorEditExists: false,

        });
    };
    handleFileUploadForAadhar = (e) => {
        this.setState({ imgfileForAadhar: e.target.files[0] });
        let splitArray = e.target.files[0].name.split(".");
        let fileExtention = splitArray[splitArray.length - 1];
        let timeStamp = Date.now();
        let fileName = `${this.props.dashboard.setSelectedBusiness.ownerId._id}-${timeStamp}.${fileExtention}`;
        this.setState({ fileNameForAadhar: fileName })
    }
    handleFileUploadForPan = (e) => {
        this.setState({ imgfileForPan: e.target.files[0] });
        let splitArray = e.target.files[0].name.split(".");
        let fileExtention = splitArray[splitArray.length - 1];
        let timeStamp = Date.now();
        let fileName = `${this.props.dashboard.setSelectedBusiness.ownerId._id}-${timeStamp}.${fileExtention}`;
        this.setState({ fileNameForPan: fileName })

    }
    closeEditDetails = () => {
        this.editDetailsFalse();
        this.setData();
        this.setState({ requiredError: false, phoneValidationError: false, aadharValidationError: false, panValidationError: false,mobileValidationError : false,  mobileValidationSpaceError : false });
    };
    editDetailsFalse = () => {
        this.setState({ editDetails: false });
    };
    editDetailsTrue = () => {
        this.setState({ editDetails: true });
    };
    saveDetails = async () => {
        let hasError = false;


        if (!this.state.primaryMobileNumber || (this.state.primaryMobileNumber.trim().length != 10 && this.state.primaryMobileNumber.trim().length != 0)) {
            this.setState({ phoneValidationError: true, });
            hasError = true;
        }
        if (this.state.mobileNumbers != "") {
            if (! /^[0-9]*$/.test(this.state.mobileNumbers)) { hasError = true; this.setState({ mobileValidationSpaceError: true }) }
            if (! /^[0-9]{10}$/.test(this.state.mobileNumbers)) {
                hasError = true;
                this.setState({ mobileValidationError: true }) }
          }

        if (!this.state.firstName || !this.state.lastName) { hasError = true }

        if (!this.state.aadharCardNumber && !this.state.panCardNumber) {
            this.setState({ aadharValidationError: true, panValidationError: true })

            hasError = true
        }

        if (!this.state.panCardNumber && (this.state.fileNameForPan || this.props.business.panCardImage)) {
            this.setState({ panValidationError: true });
            hasError = true;
        }

        if (!this.state.aadharCardNumber && (this.state.fileNameForAadhar || this.props.business.aadharCardImage)) {
            this.setState({ aadharValidationError: true });
            hasError = true;
        }


        if (!this.state.aadharCardNumber && this.state.panCardNumber) {
            if ((!this.state.fileNameForPan && !this.props.business.panCardImage) || ! /([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(this.state.panCardNumber)) {
                this.setState({ panValidationError: true });
                hasError = true;
            }
        }

        if (!this.state.panCardNumber && this.state.aadharCardNumber) {
            if ((!this.state.fileNameForAadhar && !this.props.business.aadharCardImage) || ! /([0-9]){12}$/.test(this.state.aadharCardNumber)) {
                this.setState({ aadharValidationError: true });
                hasError = true;
            }
        }

        if (this.state.panCardNumber) {
            if ((!this.state.fileNameForPan && !this.props.business.panCardImage) || ! /([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(this.state.panCardNumber)) {
                this.setState({ panValidationError: true, });
                hasError = true;
            }
        }

        if (this.state.aadharCardNumber) {
            if ((!this.state.fileNameForAadhar && !this.props.business.aadharCardImage) || ! /([0-9]){12}$/.test(this.state.aadharCardNumber)) {
                this.setState({ aadharValidationError: true, });
                hasError = true;
            }
        }

        if (!hasError) {
            if (this.state.imgfileForAadhar) {
                let payload = {
                    file: this.state.imgfileForAadhar,
                    fileName: this.state.fileNameForAadhar,
                    type: "aadhar",
                    serviceProviderId: this.props.dashboard.setSelectedBusiness.ownerId._id
                };
                this.props.updateAadharCard(payload);
                //   console.log("------AADHAR PAYLOAD-------",payload)
            }
            if (this.state.imgfileForPan) {
                let payload = {
                    file: this.state.imgfileForPan,
                    fileName: this.state.fileNameForPan,
                    type: "pan",
                    serviceProviderId: this.props.dashboard.setSelectedBusiness.ownerId._id
                };
                this.props.updatePanCard(payload);
                //   console.log("------PAN PAYLOAD-------",payload)
            }
            this.setState({
                phoneValidationError: false,
                aadharValidationError: false,
                panValidationError: false,mobileValidationSpaceError:false,
                mobileValidationError:false

            });
            let data = {
                _id: this.props.dashboard.setSelectedBusiness.ownerId._id,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                primaryMobileNumber: this.state.primaryMobileNumber,
                mobileNumbers: this.state.mobileNumbers,
                businessName: this.props.dashboard.setSelectedBusiness.businessName,
                "personalDetails.aadhar.number": this.state.aadharCardNumber,
                "personalDetails.panCard.number": this.state.panCardNumber,
                email: this.state.email
            };
            //   console.log("----DATA ON ACTION-----",data);
            if (this.state.fileNameForAadhar) {
                data["personalDetails.aadhar.fileName"] = this.state.fileNameForAadhar;
            }
            if (this.state.fileNameForPan) {
                data["personalDetails.panCard.fileName"] = this.state.fileNameForPan;
            }
            await this.props.updateBusinessPersonalDetails(data);
            this.editDetailsFalse();
            let id = { businessId: this.props.dashboard.setSelectedBusiness._id };
            await this.props.getBusinessById(id);
            //   console.log("------data for personal details------",data);
            this.props.getAadharServPro(data._id);

            this.props.getPanServPro(data._id)

        }
    };
    getMembersList = async () => {
        let payloadData = {
            businessId: this.props.dashboard.setSelectedBusiness._id
        }
        await this.props.getMembersList(payloadData);
    }


    arrayBufferToBase64 = (binary) => {
        // console.log("arrayBufferToBase64",buffer)
        // var binary = '';
        // var bytes = [].slice.call(new Uint8Array(buffer));
        // bytes.forEach((b) => binary += String.fromCharCode(b));
        return btoa(binary);
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.business.busStateChgSuccess !== null && this.state.snackOpen === false) {
            this.setState({ backDropOpen: false });
            this.setState({ snackOpen: true })
        } else if (this.props.business.busStateChgErr !== null && this.state.snackOpen === false) {
            this.setState({ backDropOpen: false });
            this.setState({ snackOpen: true });
        }
    }

    handleSnackClose = () => {
        this.setState({ snackOpen: false });
        this.props.clearBusiErrorMsg();
        this.props.clearBusiSucessMsg();
        this.setState({ buttonDisabled: false });
        this.props.history.push("/pendingBusiness");
    }

    /* Calls the action to Approve the Business */
    handleApprove = () => {
        this.setState({ acceptDialogOpen: false });
        this.props.approveBusiness(this.props.dashboard.setSelectedBusiness._id,
            this.props.dashboard.setSelectedBusiness.businessName);
        this.setState({ backDropOpen: true });
        this.setState({ buttonDisabled: true });
    };

    /* Calls the action to Reject the Business */
    handleReject = () => {
        // Close the Dialog box
        this.setState({ rejectDialogOpen: false });
        this.props.rejectBusiness(this.props.dashboard.setSelectedBusiness._id,
            this.props.dashboard.setSelectedBusiness.businessName);
        this.setState({ backDropOpen: true });
        this.setState({ buttonDisabled: true });
    };

    /* Handlers to open Accept Business Dialog box */
    handleAcceptDialogOpen = () => {
        this.setState({ acceptDialogOpen: true });
    }

    /* Handlers to open Accept Business Dialog box */
    handleAcceptDialogClose = () => {
        this.setState({ acceptDialogOpen: false });
    }

    /* Handlers to open Reject Business Dialog box */
    handleRejectDialogOpen = () => {
        this.setState({ rejectDialogOpen: true });
    }

    /* Handlers to close Reject Business Dialog box */
    handleRejectDialogClose = () => {
        this.setState({ rejectDialogOpen: false });
    }

    /* Handlers to open Business contract modal */
    handleContractModalOpen = () => {
        this.setState({ contractModalOpen: true });
        this.setState({  
            createContractFlag: true,
            initialData: {
                merchantName:this.props.dashboard.setSelectedBusiness && this.props.dashboard.setSelectedBusiness.businessName ? this.props.dashboard.setSelectedBusiness.businessName : "",
                gstNumber: this.props.dashboard.setSelectedBusiness && this.props.dashboard.setSelectedBusiness.gstin ? this.props.dashboard.setSelectedBusiness.gstin : "",
                fssai: this.props.dashboard.setSelectedBusiness && this.props.dashboard.setSelectedBusiness.FSSAILicNumber ? this.props.dashboard.setSelectedBusiness.FSSAILicNumber : "",
                shopAct: this.props.dashboard.setSelectedBusiness && this.props.dashboard.setSelectedBusiness.shopAct && this.props.dashboard.setSelectedBusiness.shopAct.number ? this.props.dashboard.setSelectedBusiness.shopAct.number : "",
                businessEmail: this.props.dashboard.setSelectedBusiness && this.props.dashboard.setSelectedBusiness.ownerId && this.props.dashboard.setSelectedBusiness.ownerId.email ? this.props.dashboard.setSelectedBusiness.ownerId.email : "",
                contactPerson: this.props.dashboard.setSelectedBusiness && this.props.dashboard.setSelectedBusiness.ownerId && this.props.dashboard.setSelectedBusiness.ownerId.firstName && this.props.dashboard.setSelectedBusiness.ownerId.lastName ? (this.props.dashboard.setSelectedBusiness.ownerId.firstName + " " + this.props.dashboard.setSelectedBusiness.ownerId.lastName ) : "",
                contactNumber: this.props.dashboard.setSelectedBusiness && this.props.dashboard.setSelectedBusiness.ownerId && this.props.dashboard.setSelectedBusiness.ownerId.primaryMobileNumber ? this.props.dashboard.setSelectedBusiness.ownerId.primaryMobileNumber : "",
            }
        });
    };

    // Create a new business contract / agreement - new functionality
    createBusinessAgreement = async (formValues) => {
        console.log("===== inside createBusinessAgreement in pending business =====");
        console.log("Payload received: " , formValues);

        let id = this.props.dashboard.setSelectedBusiness._id;
        let autorenewal = parseInt(formValues.autoRenewalYear);
        console.log(autorenewal)

        let tempDate = moment(formValues.contractStartDate).startOf("day").subtract(1,'d');
        console.log("tempDate------>", tempDate);
        let rangeContractStartTemp = new Date(moment(tempDate).startOf("day"));
        console.log("rangeContractStartTemp------>", rangeContractStartTemp);
        let rangeContractEndTemp = new Date(moment(tempDate)
          .add(autorenewal, 'y')
          .add(1,'d')
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"));

        console.log(rangeContractEndTemp);
        console.log(moment(rangeContractEndTemp).format('YYYY-MM-DD[T18:29:59.999Z]'));

        let tempEffectiveDate = moment(formValues.effectiveDate).startOf("day").subtract(1,'d');
        console.log("tempEffectiveDate------>", tempEffectiveDate);
        let rangeEffectiveStartTemp = new Date(moment(tempEffectiveDate).startOf("day"));
        console.log("rangeEffectiveStartTemp------>", rangeEffectiveStartTemp);

        let payload = {
            businessId: id,
            businessName: this.props.dashboard.setSelectedBusiness.businessName,
            businessStatus: this.props.dashboard.setSelectedBusiness.businessStatus,
            startDate: moment(rangeContractStartTemp).format('YYYY-MM-DD[T18:30:00.000Z]'),
            endDate: moment(rangeContractEndTemp).format('YYYY-MM-DD[T18:29:59.999Z]'),
            isAutoRenewal: formValues.autoRenewalToggle,
            autoRenewalDate: { years: autorenewal },
            merchantName: formValues.merchantName,
            gstNumber: formValues.gstNumber,
            legalEntityName: formValues.legalEntityName,
            FSSAILicNumber: formValues.fssai,
            shopActNumber: formValues.shopAct,
            address: {
                lineOne: formValues.addressLineOne,
                lineTwo: formValues.addressLineTwo,
                country: formValues.addressCountry,
                city: formValues.addressCity,
                state: formValues.addressState,
                pinCode: parseInt(formValues.addressPinCode)
            },
            businessEmailId: formValues.businessEmail,
            contactPerson: formValues.contactPerson,
            contactNumber: formValues.contactNumber,
            oneTimeSignUpFee: { 
                outletEntity: formValues.outletEntity, 
                rupees: formValues.outletEntitySignUpFee && formValues.outletEntitySignUpFee !== "" && formValues.outletEntitySignUpFee !== undefined && formValues.outletEntitySignUpFee !== null ? _.ceil((parseFloat(formValues.outletEntitySignUpFee)),2) : null
            },
            cancellationFeePerTransaction: { 
                cost: formValues.cancellationFee && formValues.cancellationFee !== "" && formValues.cancellationFee !== undefined && formValues.cancellationFee !== null ? _.ceil((parseFloat(formValues.cancellationFee)),2) : null
            },
            pushNotificationServiceCharges: { 
                cost: formValues.pushNotificationServicesFee && formValues.pushNotificationServicesFee !== "" && formValues.pushNotificationServicesFee !== undefined && formValues.pushNotificationServicesFee !== null ? _.ceil((parseFloat(formValues.pushNotificationServicesFee)),2) : null
            },
            otherMarketingPackages: { 
                type: formValues.otherMarketingPackages, 
                cost: formValues.otherMarketingPackagesCost && formValues.otherMarketingPackagesCost !== "" && formValues.otherMarketingPackagesCost !== undefined && formValues.otherMarketingPackagesCost !== null ? _.ceil((parseFloat(formValues.otherMarketingPackagesCost)),2) : null, 
                description: formValues.otherMarketingPackagesDescription 
            },
            servicesOpted: {
                isDeliveryOrders: formValues.isDeliveryOrders,
                isOnlineBusinessListingOnXirify: formValues.isOnlineBusinessListingOnXirify,
                isPickUpOrders: formValues.isPickUpOrders,
                isServiceAtConsumerLocation: formValues.isServiceAtConsumerLocation,
                isServiceAtBusinessLocation: formValues.isServiceAtBusinessLocation,
                isSelfDelivery: formValues.isSelfDelivery,
                isXirifyPartnerDelivery: formValues.isXirifyPartnerDelivery,
                isONDCRegistration: formValues.isONDCRegistration,
                isONDCExclusiveRegistration: formValues.isONDCExclusiveRegistration
            },
            businessConvCharges: { 
                percentage: formValues.bccPercentage && formValues.bccPercentage !== "" && formValues.bccPercentage !== undefined && formValues.bccPercentage !== null ? _.ceil((parseFloat(formValues.bccPercentage)),2) : null
            },
            effectiveDate: formValues.effectiveDate && formValues.effectiveDate !== "" && formValues.effectiveDate !== null && formValues.effectiveDate !== undefined ? moment(rangeEffectiveStartTemp).format('YYYY-MM-DD[T18:30:00.000Z]') : null,
            notifyMerchantATD: { 
                monthlyNotify: formValues.notifyMerchantMonthSelection && formValues.notifyMerchantMonthSelection !== "" && formValues.notifyMerchantMonthSelection !== undefined && formValues.notifyMerchantMonthSelection !== null ? parseInt(formValues.notifyMerchantMonthSelection) : null, 
                weeklyNotify: formValues.notifyMerchantWeekSelection && formValues.notifyMerchantWeekSelection !== "" && formValues.notifyMerchantWeekSelection !== undefined && formValues.notifyMerchantWeekSelection !== null ? parseInt(formValues.notifyMerchantWeekSelection) : null, 
                dailyNotify: formValues.notifyMerchantDaySelection && formValues.notifyMerchantDaySelection !== "" && formValues.notifyMerchantDaySelection !== undefined && formValues.notifyMerchantDaySelection !== null ? parseInt(formValues.notifyMerchantDaySelection) : null
            },
            restartMerchantATD: { 
                monthlyRestart: formValues.restartMerchantMonthSelection && formValues.restartMerchantMonthSelection !== "" && formValues.restartMerchantMonthSelection !== undefined && formValues.restartMerchantMonthSelection !== null ? parseInt(formValues.restartMerchantMonthSelection) : null, 
                weeklyRestart: formValues.restartMerchantWeekSelection && formValues.restartMerchantWeekSelection !== "" && formValues.restartMerchantWeekSelection !== undefined && formValues.restartMerchantWeekSelection !== null ? parseInt(formValues.restartMerchantWeekSelection) : null, 
                dailyRestart: formValues.restartMerchantDaySelection && formValues.restartMerchantDaySelection !== "" && formValues.restartMerchantDaySelection !== undefined && formValues.restartMerchantDaySelection !== null ? parseInt(formValues.restartMerchantDaySelection) : null 
            },
            businessConvFees: formValues.businessConvFees
        };

        console.log("PAYLOAD ====> ", payload);

        if(formValues.editContractFlag && formValues.editContractFlag === true) {
            console.log("edit api will be called");
        } else {
            console.log("create api will be called");
            await this.props.createBusinessAgreementNewContract(payload);
        }

        this.setState({ contractModalOpen: false, createContractFlag: false, backDropOpen: true });;
    };

    /* Handlers to open Business contract modal */
    handleContractModalClose = () => {
        this.setState({ contractModalOpen: false, createContractFlag: false });
    }
    handleDisableDialogOpen1 = () => {
        this.setState({ disableDialogOpen1: true });
    };

    /* Handlers to close Disable Business Dialog box */
    handleDisableDialogClose1 = () => {
        this.setState({ disableDialogOpen1: false });
    };

    handleDisableDialogOpen2 = () => {
        this.setState({ disableDialogOpen1: false });
        this.setState({ disableDialogOpen2: true });
    };

     /* Handlers to close Disable Business Dialog box */
     handleDisableDialogClose2 = () => {
        this.setState({ disableDialogOpen2: false });
    };

    handlereset = async() =>{
        // this.props.deleteAllServices(this.props.businessDetails._id)
        let payload = {
            busId:this.props.dashboard.setSelectedBusiness._id,
         
        }
        let payload2 = {
            id:this.props.dashboard.setSelectedBusiness._id,
         
        }

        await this.props.deleteAllServices(payload);
        this.setState({ disableDialogOpen2: false });
        this.setState({ disableDialogOpen1: false });
        await this.props.getServicesByBusinessId(payload2);
        await this.props.getAllVariationGroups(payload2);
        await this.props.getHeadByBusinessId(payload2);
        await this.props.getAllAddonList(payload2)
        await this.props.getHeadSubheadHierarchy(payload2);
        await this.props.getAllItemsLinkedWithAddons(payload2);
        await this.props.getHeadWithServicesByBusinessId(payload2.id);
        await this.props.getAllAddOnGroups(payload2)
        // this.fetchServices();
        

    }
    removeSubcategory = async () => {
        let payload = {
            businessId: this.props.dashboard.setSelectedBusiness._id
        };
        await this.props.deleteSubcategoryButton(payload);

    }
    addContract = (props) => {
        let payload = {
            businessId: this.props.dashboard.setSelectedBusiness._id,
            businessName: this.props.dashboard.setSelectedBusiness.businessName,
            data: {
                businessId: this.props.dashboard.setSelectedBusiness._id,
                startDate: props.contractStartDate,
                endDate: props.contractEndDate,
                agreedAmount: Number(props.agreedContrctAmount),
                isActive: true,
                payments: [{
                    amount: Number(props.amount),
                    date: new Date(),
                    mode: props.mode,
                    note: props.note
                }],
                description: props.description
            }
        }
        this.props.addContract(payload);
        this.handleContractModalClose();
        this.setState({ backDropOpen: true });
    }

    handleSearchChange = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    handleClearButton = () => {
        this.setState(
            {
                page: 0,
                searchValue: "",
                clearSearch: false,
            },
            () => this.memberDetails()
        );
    };

    memberDetails = () => {
        let data = {
            searchValue: this.state.searchValue,
            businessId: this.props.dashboard.setSelectedBusiness._id,
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
        };
        this.props.getMembersList(data);
    };

    handleSearchSubmit = (event) => {
        event.preventDefault();
        if (this.state.searchValue.trim()) {
            this.setState(
                {
                    page: 0,
                    clearSearch: true,
                },
                () => this.memberDetails()
            );
        } else {
            this.setState({ clearSearch: false, searchValue: "" }, () =>
                this.memberDetails()
            );
        }
    };

    handleDeliveryPartnerAvailabilitySwitchChange = async (event, data) => {
        this.setState({
            isDeliveryByPartnerAllowd: event.target.checked
        });
        let payload = {
            businessId: this.props.dashboard.setSelectedBusiness._id,
            data: {
                isDeliveryPartnerAllowed: event.target.checked,
                // isDeliveryByPartner: event.target.checked,
            }
        }
        if (!event.target.checked) {
            payload.data.isDeliveryByPartner = false
        }
        var result = await this.props.updateDeliveryPartnerAvailability(payload);
        if (result === null) {
            this.setState({
                isDeliveryByPartner: payload.data.isDeliveryByPartner,
            });
        }
    };

    handleBusinessMemberStatus = async (businessMemberStatus, updatedObj) => {      
        await this.props.updateBusinessPersonalDetails(updatedObj);
        this.setState({ businessMemberStatus });

    }


    render() {
        // console.log("this.props in basic business details ===>", this.props);
        const { classes } = this.props;
        let mobileNumbers = null;
        let muiAlert = null;
        let businessStatus = this.props.dashboard.setSelectedBusiness.businessStatus;
        let footerBar = null;
        let updatedAt;

        this.props.business.lastReceivedOrder = {};
        this.props.business.revenueMonthly.count = 0;
        this.props.business.revenueMonthly.totalRevenue = 0;
        this.props.business.revenueTillNow.count = 0;
        this.props.business.revenueTillNow.totalRevenue = 0;
        this.props.business.revenueToday.count = 0;
        this.props.business.revenueToday.totalRevenue = 0;
        this.props.business.revenueWeekly.count = 0;
        this.props.business.revenueWeekly.totalRevenue = 0;
        this.props.business.revenueYesterday.count = 0;
        this.props.business.revenueYesterday.totalRevenue = 0;

        if (this.props.dashboard.setSelectedBusiness.ownerId.mobileNumbers !=null && this.props.dashboard.setSelectedBusiness.ownerId.mobileNumbers.length > 0 && this.props.dashboard.setSelectedBusiness.ownerId.mobileNumbers !=""
        ) 				 {
            mobileNumbers = this.props.dashboard.setSelectedBusiness.ownerId.mobileNumbers.join();
        } else {
            mobileNumbers = 'None';
        }

        let paymentMethodOptions = [];

        if (this.props.dashboard.setSelectedBusiness.paymentMethodOptions.cod) {
            paymentMethodOptions.push("COD");
        }
        if (this.props.dashboard.setSelectedBusiness.paymentMethodOptions.upi) {
            paymentMethodOptions.push("UPI");
        }
        if (this.props.dashboard.setSelectedBusiness.paymentMethodOptions.banktransfer) {
            paymentMethodOptions.push("Bank Transfer");
        }
        if (this.props.dashboard.setSelectedBusiness.paymentMethodOptions.cheque) {
            paymentMethodOptions.push("Cheque");
        }

        if (businessStatus === "AWAITING_APPROVAL") {
            footerBar = <Grid container spacing={2}>
                  <Grid item align="right" xs={10} sm={4}>
                {  this.state.value === 3 ?
                    <Button variant="contained" color="primary"
                        disabled={this.state.resetButtonDisabled}
                        onClick={this.handleDisableDialogOpen1}
                        //style={{  height: "40px", backgroundColor: "#f50505", color: "white" }}
                        style={{ height: "40px",color: this.props.user.role==="areaManager"?"white":"", backgroundColor: this.props.user.role === "areaManager" ? '#f50505' : '' }}
                        >
                        RESET
                    </Button> :
                    null
                    }
                </Grid>
                <Grid item align="center" xs={12} sm={4}>
                    <Button variant="contained" color="primary"
                        disabled={this.state.buttonDisabled}
                        onClick={this.handleContractModalOpen}>
                        APPROVE
                    </Button>
                </Grid>
                <Grid item alignItems="center" xs={12} sm={4}>
                    <Button variant="contained" color="secondary"
                        disabled={this.state.buttonDisabled}
                        onClick={this.handleRejectDialogOpen}>
                        REJECT
                    </Button>
                </Grid>
            </Grid>
        } else if (businessStatus === "REJECTED") {
            updatedAt = new Date(this.props.dashboard.setSelectedBusiness.updatedAt);
            footerBar = <Paper variant="outlined" square className={classes.rejectBox}>
                Business Rejected on {updatedAt.toLocaleDateString("en-US",
                    {
                        weekday: "long", year: "numeric", month: "short", day: "numeric",
                        hour: "numeric", minute: "numeric"
                    })}
            </Paper>
        }

        if (this.props.business.busStateChgSuccess) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
                {this.props.business.busStateChgSuccess}
            </MuiAlert>
        } else if (this.props.business.busStateChgErr) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                {this.props.business.busStateChgErr}
            </MuiAlert>
        }
        let clearSearchButton = null;

        if (this.state.clearSearch) {
            clearSearchButton = (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleClearButton()}
                    className={classes.clearButton}
                >
                    Clear
                </Button>
            );
        }

        return (
            <div className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container}>
                    {/* <Paper elevation={3} > */}
                    <Grid alignItems="center">
                        <Typography variant="h6" gutterBottom>
                            {this.props.dashboard.setSelectedBusiness.businessName}
                        </Typography>
                    </Grid>
                    <Tabs value={this.state.value} onChange={this.handleChangeTab} indicatorColor="primary" textColor="primary" centered>
                        <Tab label="STATISTICS" />
                        <Tab label="PERSONAL" />
                        <Tab label="BUSINESS" />
                        <Tab label="CATALOG" />
                        <Tab label="CONTACTS" />
                        {this.props.user.role === "areaManager" ? <Tab label="DELIVERY PARTNER" /> : null}

                    </Tabs>
                    {/* {this.props.user.role === "admin" ?
                        <Grid xs={12}>
                            <Button
                                style={{ float: "right", fontWeight: "bold" }}
                                variant="contained"
                                color="secondary"
                                onClick={this.removeSubcategory}
                            >
                                REMOVE SUBCATEGORY FROM ALL SERVICES
                            </Button>
                        </Grid>
                        : null} */}

                    <TabPanel value={this.state.value} index={0}>
                        {
                            <div>
                                <Grid container spacing={3}>
                                    {this.props.business.lastReceivedOrder &&
                                        this.props.business.lastReceivedOrder.creationDate ? (
                                        <Grid item xs={4}>
                                            <Card>
                                                <CardContent>
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="textSecondary"
                                                        gutterBottom
                                                    >
                                                        Last Request
                                                    </Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                Request Date
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                display="block"
                                                                gutterBottom
                                                            >
                                                                {moment(
                                                                    this.props.business.lastReceivedOrder
                                                                        .creationDate
                                                                ).format("DD MMM YYYY hh:mm A")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                Cost Type
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                {this.props.business.lastReceivedOrder &&
                                                                    this.props.business.lastReceivedOrder
                                                                        .services &&
                                                                    this.props.business.lastReceivedOrder.services
                                                                        .length > 0 &&
                                                                    this.props.business.lastReceivedOrder
                                                                        .services[0].cost.costType ? this.props.business.lastReceivedOrder &&
                                                                        this.props.business.lastReceivedOrder
                                                                            .services &&
                                                                        this.props.business.lastReceivedOrder.services
                                                                            .length > 0 &&
                                                                this.props.business.lastReceivedOrder
                                                                    .services[0].cost.costType : '-'}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                Amount
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                display="block"
                                                                gutterBottom
                                                            >
                                                                {this.props.business.lastReceivedOrder.amount ? this.props.business.lastReceivedOrder.amount.toFixed(2) : ''}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                Request Status
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                display="block"
                                                                gutterBottom
                                                            >
                                                                {
                                                                    this.props.business.lastReceivedOrder
                                                                        .currentStatus
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                Payment Status
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                display="block"
                                                                gutterBottom
                                                            >
                                                                {
                                                                    this.props.business.lastReceivedOrder
                                                                        .paymentStatus
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ) : null}
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Till Now
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Amount:{" "}
                                                    {this.props.business.revenueTillNow.totalRevenue ? this.props.business.revenueTillNow.totalRevenue.toFixed(2) : 0}
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Requests: {this.props.business.revenueTillNow.count}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Today
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Amount:{" "}
                                                    {this.props.business.revenueToday.totalRevenue ? this.props.business.revenueToday.totalRevenue.toFixed(2) : 0}
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Requests: {this.props.business.revenueToday.count}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Yesterday
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Amount:{" "}
                                                    {this.props.business.revenueYesterday.totalRevenue ? this.props.business.revenueYesterday.totalRevenue.toFixed(2) : 0}
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Requests: {this.props.business.revenueYesterday.count}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Weekly
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Amount:{" "}
                                                    {this.props.business.revenueWeekly.totalRevenue ? this.props.business.revenueWeekly.totalRevenue.toFixed(2) : 0}
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Requests: {this.props.business.revenueWeekly.count}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Monthly
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Amount:{" "}
                                                    {this.props.business.revenueMonthly.totalRevenue ? this.props.business.revenueMonthly.totalRevenue.toFixed(2) : 0}
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Requests: {this.props.business.revenueMonthly.count}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Orders
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.orders}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'orders')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.orders ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.orders ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.orders ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Cancel
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.cancel}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'cancel')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.cancel ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.cancel ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.cancel ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Shoppers
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.shoppers}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'shoppers')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.shoppers ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.shoppers ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.shoppers ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Settlement
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.settlement}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'settlement')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.settlement ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.settlement ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.settlement ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Online Payment
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.onlinePayment}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'onlinePayment')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.onlinePayment ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.onlinePayment ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.onlinePayment ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Direct Payment
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.directPayment}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'directPayment')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.directPayment ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.directPayment ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.directPayment ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Campaign
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.campaign}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'campaign')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.campaign ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.campaign ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.campaign ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    User Sentiment
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.userSentiment}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'userSentiment')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.userSentiment ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.userSentiment ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.userSentiment ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </TabPanel>
                    {/* <TabPanel value={this.state.value} index={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      <b>First Name</b>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      {this.props.dashboard.setSelectedBusiness.ownerId.firstName}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <b>Last Name</b>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      {this.props.dashboard.setSelectedBusiness.ownerId.lastName}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <b>Email</b>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      {this.props.dashboard.setSelectedBusiness.ownerId.email}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <b>Mobile Number</b>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      {this.props.dashboard.setSelectedBusiness.ownerId.primaryMobileNumber}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <b>Phone Numbers</b>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      {mobileNumbers}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <b>Address</b>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      {this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.address}
                    </Grid>
                  </Grid>

                  <Grid container direction="row" justify="space-evenly" alignItems="center" className={classes.img}>
                    <Grid item xs={12} sm={3}>
                      <b>Aadhar</b>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      {this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.aadhar.number ? this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.aadhar.number : "NA"}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {
                        this.props.business.aadharCard!=null && this.props.business.aadharCard!=undefined && this.props.business.aadharCard!="" ?
                        <img src={URL.createObjectURL(new Blob([this.props.business.aadharCard], { type: 'image/jpeg' }))}
                        alt="Aadhar Card" width="400" height="250" />
                        : "NA"
                      }
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-evenly" alignItems="center" className={classes.img}>
                    <Grid item xs={12} sm={3}>
                      <b>PAN</b>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      {this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.panCard.number ? this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.panCard.number : "NA"}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {
                        this.props.business.panCard!=null && this.props.business.panCard!=undefined && this.props.business.panCard!="" ?
                        <img src={URL.createObjectURL(new Blob([this.props.business.panCard], { type: 'image/jpeg' }))}
                        alt="Pan Card" width="400" height="250" />
                        : "NA"
                      }
                      
                    </Grid>
                  </Grid>

            </TabPanel> */}

                    <TabPanel value={this.state.value} index={1}>
                        {
                            <div>
                                <Grid item xs={3} sm={3} style={{ float: "right" }}>
                                    {
                                        (this.props.user.role === "areaManager" || this.props.user.role === "admin") && !this.state.editDetails ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                                onClick={this.editDetailsTrue}
                                            >
                                                EDIT PERSONAL DETAILS
                                            </Button> : null
                                    }
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={3}>
                                        <b>First Name</b>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        {/* {this.props.dashboard.setSelectedBusiness.ownerId.firstName} */}
                                        {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                            <Grid item xs={12} sm={9}>
                                                <TextField
                                                    name="firstName"
                                                    label="First Name"
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    value={this.state.firstName}
                                                    // value={this.state.firstName ? this.state.firstName.trim().replace(/\D/g, "") : ""}
                                                    error={!this.state.firstName ? true : (this.state.firstNameValidationError ? true : false)}
                                                />
                                                {!this.state.firstName ? (
                                                    <FormHelperText style={{ color: "red" }}>
                                                        Required
                                                    </FormHelperText>
                                                ) : this.state.firstNameValidationError ? (
                                                    <FormHelperText style={{ color: "red" }}>
                                                        Invalid First Name
                                                    </FormHelperText>) :
                                                    null}
                                            </Grid>
                                        ) : (
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.firstName}
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Last Name</b>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        {/* {this.props.dashboard.setSelectedBusiness.ownerId.lastName} */}
                                        {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                            <Grid item xs={12} sm={9}>
                                                <TextField
                                                    name="lastName"
                                                    label="Last Name"
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    value={this.state.lastName}
                                                    error={!this.state.lastName ? true : (this.state.lastNameValidationError ? true : false)}
                                                />
                                                {!this.state.lastName ? (
                                                    <FormHelperText style={{ color: "red" }}>
                                                        Required
                                                    </FormHelperText>
                                                ) : this.state.lastNameValidationError ? (
                                                    <FormHelperText style={{ color: "red" }}>
                                                        Invalid Last Name
                                                    </FormHelperText>)
                                                    : null}
                                            </Grid>
                                        ) : (
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.lastName}
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Email</b>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        {/* {this.props.dashboard.setSelectedBusiness.ownerId.email} */}
                                        {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                            <TextField
                                                name="email"
                                                label="Email"
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                value={this.state.email}
                                            />
                                        ) : (
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.email}
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Primary Mobile Number</b>
                                    </Grid>
                                    {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                        <Grid item xs={12} sm={9}>
                                            <TextField
                                                name="primaryMobileNumber"
                                                label="Primary Mobile Number"
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                inputProps={{ maxLength: 10 }}
                                                value={this.state.primaryMobileNumber ? this.state.primaryMobileNumber.trim().replace(/\D/g, "") : ""}
                                                error={!this.state.primaryMobileNumber ? true : (this.state.phoneValidationError ? true : false)}
                                            />
                                            {!this.state.primaryMobileNumber ? (
                                                <FormHelperText style={{ color: "red" }}>
                                                    Required
                                                </FormHelperText>
                                            ) : this.state.phoneValidationError ? (
                                                <FormHelperText style={{ color: "red" }}>
                                                    Invalid Mobile Number
                                                </FormHelperText>
                                            ) : null}
                                        </Grid>
                                    ) : (<Grid item xs={12} sm={9}>
                                        <Typography>
                                            {this.props.dashboard.setSelectedBusiness.ownerId.primaryMobileNumber}
                                        </Typography>
                                    </Grid>
                                    )}


                                    <Grid item xs={12} sm={3}>
                                        <b>Mobile Number</b>
                                    </Grid>
                                    
                                        {/* {mobileNumbers} */}
                                        {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                            <Grid item xs={12} sm={9}>
                                            <TextField
                                                name="mobileNumbers"
                                                label="Mobile Number"
                                                onChange={this.handleChange}
                                                type="number"
                                                variant="outlined"
                                                value={this.state.mobileNumbers}
                                                error={this.state.mobileValidationError ? true :(this.state.mobileValidationSpaceError ? true:false)}
                                            />
                                            {this.state.mobileValidationSpaceError ? (
                                                <FormHelperText style={{ color: "red" }}>
                                                  Alphabet/spaces are not allowed
                                                </FormHelperText>
                                                ) : (
                                        this.state.mobileValidationError ? (
                                                <FormHelperText style={{ color: "red" }}>
                                                Mobile number should be 10 digits
                                                </FormHelperText>
                                            ) : null)
                                        }
                                        </Grid>) : (
                                            <Grid item xs={12} sm={9}>
                                            <Typography>
                                                {mobileNumbers}
                                            </Typography>
                                            </Grid>
                                        )}

                                    <Grid item xs={12} sm={3}>
                                        <b>Address</b>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        {/* {this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.address} */}
                                        {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                            <TextField
                                                name="address"
                                                label="Address"
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                value={this.state.address}
                                            />
                                        ) : (
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.address}
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={12}></Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Aadhar Card</b>
                                    </Grid>

                                    {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name="aadharCardNumber"
                                                label="aadhar Number"
                                                onChange={this.handleChange}
                                                inputProps={{ maxLength: 12 }}
                                                variant="outlined"
                                                value={this.state.aadharCardNumber}
                                            // error={!this.state.aadharCardNumber ? true : (this.state.aadharValidationError ? true : false)}

                                            />
                                            {this.state.aadharValidationError ?
                                                <FormHelperText style={{ color: "red" }}>
                                                    {!this.state.aadharCardNumber ? `Aadhar Number is required` : (! /([0-9]){12}$/.test(this.state.aadharCardNumber) ? `Inavalid Aadhar Number` :
                                                        (!this.state.fileNameForAadhar ? `Aadhar Photo is required` : ''))}
                                                </FormHelperText>
                                                : null
                                            }
                                        </Grid>
                                    ) : (<Grid item xs={12} sm={3}>
                                        {!this.state.editDetails && this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.aadhar.number ?
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.aadhar.number}
                                            </Typography> : "Not Available"}
                                    </Grid>
                                    )}

                                    <Grid item xs={12}></Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Aadhar Card Image</b>
                                    </Grid>
                                    {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ?

                                        <Grid item xs={12} sm={3} style={{ paddingLeft: "6px" }}>
                                            <Input type="file" name="aadhar" inputProps={{ accept: "image/x-png,image/jpeg" }} onChange={this.handleFileUploadForAadhar} placeholder="White Icon" disableUnderline />
                                        </Grid>
                                        :
                                        <Grid item xs={12} sm={9}>
                                            {!this.state.editDetails && this.props.business.aadharCardImage ?
                                                <img
                                                    src={URL.createObjectURL(
                                                        new Blob([this.props.business.aadharCardImage], {
                                                            type: "image/jpeg",
                                                        })
                                                    )}
                                                    alt="aadhar card"
                                                    width="400"
                                                    height="250"
                                                /> : "Not Available"}
                                        </Grid>}

                                    <Grid item xs={12}></Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Pan Card</b>
                                    </Grid>

                                    {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name="panCardNumber"
                                                label="Pancard Number"
                                                onChange={this.handleChange}
                                                inputProps={{ maxLength: 10 }}
                                                variant="outlined"
                                                value={this.state.panCardNumber}
                                            // error={!this.state.panCardNumber ? true : (this.state.panValidationError ? true : false)}
                                            />
                                            {
                                                this.state.panValidationError ?
                                                    <FormHelperText style={{ color: "red" }}>
                                                        {!this.state.panCardNumber ? `Pan Number is required` : (! /([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(this.state.panCardNumber) ? `Inavalid Pan Number` :
                                                            (!this.state.fileNameForPan ? `Pan Photo is required` : ''))}
                                                    </FormHelperText>
                                                    : null
                                            }
                                        </Grid>
                                    ) : (<Grid item xs={12} sm={3}>
                                        {!this.state.editDetails && this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.panCard.number ?
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.panCard.number}
                                            </Typography> :
                                            "Not Available"}
                                    </Grid>
                                    )}

                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12} sm={3}>
                                        <b>Pan Card Image</b>
                                    </Grid>
                                    {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ?

                                        <Grid item xs={12} sm={6} style={{ paddingLeft: "6px" }}>
                                            <Input type="file" name="pan" inputProps={{ accept: "image/x-png,image/jpeg" }} onChange={this.handleFileUploadForPan} placeholder="White Icon" disableUnderline />
                                        </Grid>
                                        :
                                        <Grid item xs={12} sm={9}>
                                            {!this.state.editDetails && this.props.business.panCardImage ?
                                                <img
                                                    src={URL.createObjectURL(
                                                        new Blob([this.props.business.panCardImage], {
                                                            type: "image/jpeg",
                                                        })
                                                    )}
                                                    alt="pan card"
                                                    width="400"
                                                    height="250"
                                                /> : "Not Available"}
                                        </Grid>}


                                    {this.state.editDetails ? (
                                        <Grid
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                        >
                                            <Button
                                                style={{ width: "15%", margin: "10px" }}
                                                variant="contained"
                                                margin="normal"
                                                component="span"
                                                onClick={this.closeEditDetails}
                                            >
                                                CANCEL
                                            </Button>
                                            <Button
                                                style={{ width: "15%", margin: "10px" }}
                                                variant="contained"
                                                color="primary"
                                                margin="normal"
                                                component="span"
                                                onClick={this.saveDetails}
                                            >
                                                SAVE
                                            </Button>
                                        </Grid>

                                    ) : null}
                                </Grid>
                            </div>
                        }
                    </TabPanel>

                    <TabPanel value={this.state.value} index={2}>
                        {
                            <BusinessDetailsSection specialityDetails={this.state.specialityListArr}
                            cuisineDetails={this.state.cuisineListArr}
                                businessDetails={this.props.dashboard.setSelectedBusiness}
                                dashboardDetails={this.props.dashboard.getMasterOTP}
                            />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.value} index={3}>
                        {
                            <ServiceSection
                                businessDetails={this.props.dashboard.setSelectedBusiness}
                            />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.value} index={4}>
                        {this.props.business &&
                            this.props.business.businessMembersDetails ? (
                            <Container>
                                <Container className={classes.searchPaper}>
                                    <Paper>
                                        <form onSubmit={this.handleSearchSubmit}>
                                            <InputBase
                                                placeholder="Search"
                                                value={this.state.searchValue}
                                                style={{ width: "240px", margin: "0px 10px" }}
                                                onChange={this.handleSearchChange}
                                            />
                                            <IconButton type="submit">
                                                <SearchIcon style={{ float: "right" }} />
                                            </IconButton>
                                        </form>
                                    </Paper>
                                    {clearSearchButton}
                                </Container>
                                <MembersTable
                                    rowData={this.props.business.businessMembersDetails}
                                    handleBusinessMemberStatus={this.handleBusinessMemberStatus}  
                                    order={this.state.order === 1 ? "asc" : "desc"}
                                    orderBy={this.state.orderBy}
                                    page={this.state.page}
                                    rowsPerPage={this.state.rowsPerPage}
                                    setOrder={this.setOrder}
                                    setOrderBy={this.setOrderBy}
                                    setPage={this.setPage}
                                    setRowsPerPage={this.setRowsPerPage}
                                    history={this.props.history}
                                    role={this.props.dashboard.headerName}
                                />
                            </Container>
                        ) : null}
                    </TabPanel>
                    {this.props.user.role === "areaManager" ?
                        <TabPanel value={this.state.value} index={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Grid container justify="space-between">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={3}>
                                                        <Grid item>
                                                            <Typography
                                                                variant="subtitle2" gutterBottom>
                                                                Delivery Partner Availability
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        size="small"
                                                                        checked={this.state.isDeliveryByPartnerAllowd}
                                                                        onChange={this.handleDeliveryPartnerAvailabilitySwitchChange}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                style={{ fontSize: "10px", color: this.state.isDeliveryByPartnerAllowd ? 'black' : 'red' }}
                                                                label={this.state.isDeliveryByPartnerAllowd ? 'On' : 'Off'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid item>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom>
                                                                (Turning this OFF will disable the delivery partner services for this business)
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Grid container justify="space-between">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={3}>
                                                        <Grid item>
                                                            <Typography
                                                                variant="subtitle2" gutterBottom>
                                                                Delivery Partner Selected By Business
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Grid item>
                                                            <Typography
                                                                color="primary">
                                                                {this.state.isDeliveryByPartner === true ? "Yes" : "No"}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Grid item>
                                                            <Typography
                                                                variant="subtitle2" gutterBottom>
                                                                Initially Accepted On
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Grid item>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom>
                                                                {this.props && this.props.dashboard && this.props.dashboard.setSelectedBusiness && this.props.dashboard.setSelectedBusiness.deliveryByPartnerAccepted ? moment(this.props.dashboard.setSelectedBusiness.deliveryByPartnerAccepted).format("DD MMM YYYY hh:mm A") : ""}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </TabPanel> : null
                    }
                    {footerBar}
                    {/* </Paper> */}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.snackOpen}
                        autoHideDuration={6000}
                        onClose={this.handleSnackClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}>
                        {muiAlert}
                    </Snackbar>
                    <Dialog open={this.state.rejectDialogOpen} onClose={this.handleRejectDialogClose}>
                        <DialogTitle>{"Do you want to Reject the Business?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText color="black">
                                Rejected Business is not available to consumers on Xirify platform.
                                Rejected Business cannot be enabled again.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleRejectDialogClose} color="mediumblue">
                                No
                            </Button>
                            <Button onClick={this.handleReject} color="mediumblue" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.acceptDialogOpen} onClose={this.handleAcceptDialogClose}>
                        <DialogTitle>{"Do you want to Approve the Business?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText color="black">
                                Approved Business will be available to consumers on Xirify platform.
                                Once Approved the Business can be Enabled or Disabled.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleAcceptDialogClose} color="mediumblue">
                                No
                            </Button>
                            <Button onClick={this.handleApprove} color="mediumblue" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                    open={this.state.disableDialogOpen1}
                    onClose={this.handleDisableDialogClose1}
                >
                    <DialogTitle>{"You are Deleting the listed services. Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="black">
                        You are deleting All services, Heads, Subheads, Addons, and Variations linked with the services. Are you sure to delete all?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDisableDialogClose1} color="mediumblue">
                            No
                        </Button>
                        <Button onClick={  this.handleDisableDialogOpen2} color="mediumblue" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.disableDialogOpen2}
                    onClose={this.handleDisableDialogClose2}
                >
                    <DialogTitle>{"Are you sure you want to delete the listed services?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="black">
                            
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDisableDialogClose2} color="mediumblue">
                            No
                        </Button>
                        <Button onClick={this.handlereset} color="mediumblue" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                
                    <Modal
                        className={classes.modal}
                        open={this.state.contractModalOpen}
                        onClose={this.handleContractModalClose}
                        closeAfterTransition>
                        {/* Commented older contract form component call */}
                        {/* <Container className={classes.contractModalContainer}>
                            <ContractForm onSubmit={this.addContract} selectedBusiness={this.props.dashboard.setSelectedBusiness} onClose={this.handleContractModalClose} />
                        </Container> */}

                            <Container className={classes.modalBusinessAgreement}>
                                <BusinessAgreementForm
                                onSubmit={this.createBusinessAgreement}
                                selectedBusiness={this.props.dashboard.setSelectedBusiness}
                                onClose={this.handleContractModalClose}
                                businessTag="pending"
                                createContractFlag={this.state.createContractFlag}
                                initialValues={this.state.initialData}
                            />
                    </Container>
                    </Modal>

                    <Backdrop className={classes.backdrop} open={this.state.backDropOpen}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Container>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        dashboard: state.dashboard,
        business: state.business,
        user: state.auth.user
    }
};

export default connect(mapStateToProps, {
    deleteSubcategoryButton,
    showPendingBusinessDetails, approveBusiness, getAadharServPro, fetchEnvs, updateAadharCard, updatePanCard,
    rejectBusiness, getPanServPro, clearBusiSucessMsg, clearBusiErrorMsg,
    addContract, getShopActServPro, getMembersList, updateDeliveryPartnerAvailability, clearAadharCardMsg,
    getHeadByBusinessId,
    clearPanCardMsg, updateBusinessPersonalDetails, getBusinessById,deleteAllServices,getServicesByBusinessId,getAllVariationGroups,getAllAddonList,getAllItemsLinkedWithAddons,getHeadSubheadHierarchy ,getHeadWithServicesByBusinessId, createBusinessAgreementNewContract ,   getAllAddOnGroups
})(withStyles(styles)(BasicBusinessDetails));