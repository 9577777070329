import {
  CHANGE_HEADER_NAME,
  SHOW_PENDING_BUSINESS_DETAILS,
  SHOW_APPROVED_BUSINESS_DETAILS,
  SET_XIRIFY_STATS,
  SET_CONSUMER_LIST,
  SET_CONSUMER_LIST_WEBPUSHNOTIFICATION,
  REQUEST_STATS,
  SET_RELEASES,
  SET_QR_CODE_STATUS,
  SET_SP_LIST,
  SET_SP_DETAILS,
  SET_BUSINESS_MASTER_OTP,
  GET_MASTER_OTP,
  SET_CONSUMER_MASTER_OTP,
  GET_CONSUMER_MASTER_OTP,
  SET_CONSUMER_LIST_EMPTY,
  SET_CONFIG,
  GET_PG_RECORDS,
  SET_CONFIG_ORDERBY,
  SET_SELECTED_CONSUMER,
  REQUEST_CALL_ICON_HIT_RECORDS,
  REQUEST_PAYMENT_GATEWAY_RECORDS,
  REQUEST_PAYMENT_GATEWAY_STOP_LOADING_RECORDS,
  GET_ALL_OPEN_CLOSE_STATUSES,
  GET_ALL_OPEN_CLOSE_EXPORT_STATUSES,
  REQUEST_GET_ALL_OPEN_CLOSE_STATUSES,
  REQUEST_GET_ALL_OPEN_CLOSE_EXPORT_STATUSES,
  GET_ALL_CALL_ICON_HIT_RECORDS,
  CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL_COMPLETED,
  CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL,
  REQUEST_CRON_PERFORMANCE_RECORDS,
  GET_ALL_CRON_PERFORMANCE_RECORDS,
  CRON_JOB_PERF_DOWNLOAD_EXCEL,
  CRON_JOB_PERF_DOWNLOAD_EXCEL_COMPLETED,
  REQUEST_GET_ALL_ITEMS_AVAILABILITY_STATUSES,
  REQUEST_GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES,
  GET_ALL_ITEMS_AVAILABILITY_STATUSES,
  GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES,
  SET_ORDER_CANCELLATION_REASON_SUCCESS_MESSAGE,
  SET_ORDER_CANCELLATION_REASON_ERROR_MESSAGE,
  GET_ADMIN_REASONS_FOR_ORDER_CANCELLATION,
  CHANGE_CONSUMER_STATUS,
  SET_CONSUMER_STATUS_ERROR_MESSAGE,
  GET_BUSINESS_DASHBOARD_CRON_DATA,
  GET_COUPON_EXPIRY_CRON,
  GET_COUPON_EXPIRY_CRON_ERROR_MESSAGE,
  GET_DELETE_OPEN_CLOSE_RECORDS,
  GET_DELETE_OPEN_CLOSE_RECORDS_ERROR_MESSAGE,
  BUSINESS_DASHBOARD_CRON_ERROR_MESSAGE,
  BUSINESS_DASHBOARD_CRON,
  BUSINESS_DASHBOARD_CRON_RESET,
  GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_SUCCESS,
  GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_ERROR,
  GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS,
  GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS_ERROR_MESSAGE,
  GET_DELETE_ITEMS_AVAILABILITY_RECORDS,
  GET_DELETE_ITEMS_AVAILABILITY_RECORDS_ERROR_MESSAGE,
  GET_BUSINESSES_FOR_ADDRESS,
  ADD_DELETE_BUSINESS,
  ADD_DELETE_BUSINESS_ERROR,
  GET_CONSUMER_CART_DATA,
  ADD_CONSUMER_TAGS
} from "../actions/types";

const INITIAL_STATE = {
  headerName: "Dashboard",
  showPendingBusinessDetails: false,
  showApprovedBusinessDetails: false,
  setSelectedBusiness: null,
  xirifyStats: {},
  consumerList: {},
  isFetching: false,
  releases: [],
  QRCodeStatus: null,
  serviceProvidersList: {},
  serviceProviderDetails: {},
  getMasterOTP: [],
  getAdminOrderCancellationReasons: [],
  setBusinessMasterOTP: {},
  getConsumerMasterOTP: [],
  setConsumerMasterOTP: {},
  setConfigStatus: "",
  onlinePayentRecods: {},
  orderByTimeSucessMsg: "",
  callRecordsList: [],
  cronJobPerfList: [],
  callIconHitExcelRecords: [],
  openCloseBusinessList: [],
  openCloseBusinessExportList: [],
  itemsAvailabilityList:[],
  itemsAvailabilityExportList:[],
  sucessMsg: null,
  errorMsg: null,
  ops: "",
  businessDashboardSucessMsg: "",
  businessDashboardErrorMsg: "",
  couponExpirySuccessmsg:"",
  couponExpiryErrorMsg: "",
  deleteOpenCloseRecordsSuccessMsg:"",
  deleteOpenCloseRecordsErrorMsg:"",
  cashfreeSplitSettlemetSuccessMsg : "",
  cashfreeSplitSettlemetErrorMsg : "",
  deleteOpenCloseRecordsBusinessSuccessMsg:"",
  deleteOpenCloseRecordsBusinessErrorMsg:"",
  deleteItemsAvailabilitySuccessMsg:"",
  deleteItemsAvailabilityErrorMsg:"",
  consumerCartData: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_HEADER_NAME:
      return {
        ...state,
        headerName: action.payload,
      };
    case REQUEST_STATS:
      return { ...state, isFetching: action.payload };
    case BUSINESS_DASHBOARD_CRON:
      return { ...state, isFetching: action.payload };
    case BUSINESS_DASHBOARD_CRON_RESET:
      return { ...state, isFetching: action.payload };
    case REQUEST_PAYMENT_GATEWAY_RECORDS:
      return { ...state, isFetching: action.payload };
    case REQUEST_PAYMENT_GATEWAY_STOP_LOADING_RECORDS:
      return { ...state, isFetching: action.payload };
    case SHOW_PENDING_BUSINESS_DETAILS:
      return {
        ...state,
        showPendingBusinessDetails: !state.showPendingBusinessDetails,
        setSelectedBusiness: action.payload,
      };
    case SHOW_APPROVED_BUSINESS_DETAILS:
      return {
        ...state,
        showApprovedBusinessDetails: !state.showApprovedBusinessDetails,
        setSelectedBusiness: action.payload,
      };
    case SET_XIRIFY_STATS:
      return { ...state, xirifyStats: action.payload };
    case SET_CONSUMER_LIST:
      return { ...state, consumerList: action.payload, isFetching: false };
    case  SET_CONSUMER_LIST_WEBPUSHNOTIFICATION:
      return { ...state, consumerList: action.payload, isFetching: false };
    case SET_RELEASES:
      return { ...state, releases: action.payload, isFetching: false };
    case SET_QR_CODE_STATUS:
      return { ...state, QRCodeStatus: action.payload, isFetching: false };
    case SET_SP_LIST:
      return { ...state, serviceProvidersList: action.payload, isFetching: false };
    case SET_SP_DETAILS:
      return { ...state, serviceProviderDetails: action.payload, isFetching: false };
    case SET_BUSINESS_MASTER_OTP:
      return { ...state, setBusinessMasterOTP: action.payload, isFetching: false };
    case GET_MASTER_OTP:
      return { ...state, getMasterOTP: action.payload, isFetching: false };
    case GET_ADMIN_REASONS_FOR_ORDER_CANCELLATION:
      return { ...state, getAdminOrderCancellationReasons: action.payload, isFetching: false };
    case SET_CONSUMER_MASTER_OTP:
      return { ...state, setConsumerMasterOTP: action.payload, isFetching: false };
    case GET_CONSUMER_MASTER_OTP:
      return { ...state, getConsumerMasterOTP: action.payload, isFetching: false };
    case SET_CONSUMER_LIST_EMPTY:
      return { ...state, consumerList: action.payload, isFetching: false };
    case SET_SELECTED_CONSUMER:
      return { ...state, selectedConsumer: action.payload};   
    case SET_CONFIG:
      return { ...state, setConfigStatus: action.payload };
    case GET_PG_RECORDS:
      return { ...state, onlinePayentRecods: action.payload };
    case SET_CONFIG_ORDERBY:
      return {
          ...state,
          orderByTimeSucessMsg: action.payload
      };
    case REQUEST_CALL_ICON_HIT_RECORDS:
      return { ...state, isFetching: action.payload };
    case GET_ALL_CALL_ICON_HIT_RECORDS:
      return { ...state, callRecordsList: action.payload, isFetching: false };
    case CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL:
      return {...state, callIconHitExcelRecords: action.payload, isFetching: true};
    case CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL_COMPLETED:
      return {...state, callIconHitExcelRecords: action.payload, isFetching: false};
    case REQUEST_GET_ALL_OPEN_CLOSE_EXPORT_STATUSES:
      return { ...state, isFetching: action.payload };
    case REQUEST_GET_ALL_OPEN_CLOSE_STATUSES:
      return { ...state, isFetching: action.payload };
    case GET_ALL_OPEN_CLOSE_STATUSES:
      return {...state, openCloseBusinessList: action.payload, isFetching: false}
    case GET_ALL_OPEN_CLOSE_EXPORT_STATUSES:
      return {...state, openCloseBusinessExportList: action.payload, isFetching: false}
    

    case REQUEST_GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES:
        return { ...state, isFetching: action.payload }; 
    case REQUEST_GET_ALL_ITEMS_AVAILABILITY_STATUSES:
      return { ...state, isFetching: action.payload }; 
    case GET_ALL_ITEMS_AVAILABILITY_STATUSES:
        return {...state, itemsAvailabilityList: action.payload, isFetching: false}
    case GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES:
        return {...state, itemsAvailabilityExportList: action.payload, isFetching: false}      
    
    case REQUEST_CRON_PERFORMANCE_RECORDS:
      return { ...state, isFetching: action.payload };
    case GET_ALL_CRON_PERFORMANCE_RECORDS:
      return { ...state, cronJobPerfList: action.payload, isFetching: false };
    case CRON_JOB_PERF_DOWNLOAD_EXCEL:
      return {...state, cronJobPerfList: action.payload, isFetching: true};
    case CRON_JOB_PERF_DOWNLOAD_EXCEL_COMPLETED:
      return {...state, cronJobPerfList: action.payload, isFetching: false};
    case SET_ORDER_CANCELLATION_REASON_SUCCESS_MESSAGE:
      return {
          ...state,
          sucessMsg: action.payload,
          // ops: action.payload.ops
      };
    case SET_ORDER_CANCELLATION_REASON_ERROR_MESSAGE:
      return {
          ...state,
          errorMsg: action.payload,
          // ops: action.payload.ops
      };
    case GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_SUCCESS:
      return {
        ...state,
        cashfreeSplitSettlemetSuccessMsg: action.payload.msg,
        // ops: action.payload.ops
      };
    case GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_ERROR:
      return {
        ...state,
        cashfreeSplitSettlemetErrorMsg: action.payload.msg,
        // ops: action.payload.ops
      };
    case GET_BUSINESS_DASHBOARD_CRON_DATA:
      return {
          ...state,
          businessDashboardSucessMsg: action.payload
      };
    case GET_COUPON_EXPIRY_CRON:      
     { 
      return {
          ...state,
          couponExpirySuccessmsg: action.payload
      };}
    case BUSINESS_DASHBOARD_CRON_ERROR_MESSAGE:
      return {
          ...state,
          businessDashboardErrorMsg: action.payload
      };
    case GET_COUPON_EXPIRY_CRON_ERROR_MESSAGE:
      return {
          ...state,
          couponExpiryErrorMsg: action.payload
      };
    case CHANGE_CONSUMER_STATUS:
        return {
            ...state,
            sucessMsg: action.payload.msg,
            ops: action.payload.ops
        };
    case SET_CONSUMER_STATUS_ERROR_MESSAGE:
        return {
            ...state,
            errorMsg: action.payload.msg,
            ops: action.payload.ops
        };
        case GET_DELETE_OPEN_CLOSE_RECORDS:      
        { 
         return {
             ...state,
          deleteOpenCloseRecordsSuccessMsg: action.payload
         };}
         case GET_DELETE_OPEN_CLOSE_RECORDS_ERROR_MESSAGE:
          return {
              ...state,
              deleteOpenCloseRecordsErrorMsg: action.payload
          };

          case GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS: 
          //console.log(action.payload)  
                   return {
               ...state,
               deleteOpenCloseRecordsBusinessSuccessMsg: action.payload.msg,
               ops: action.payload.ops,
           };
           case GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS_ERROR_MESSAGE:
            return {
                ...state,
                deleteOpenCloseRecordsBusinessErrorMsg: action.payload.msg,
                ops: action.payload.ops,
            };

            case GET_DELETE_ITEMS_AVAILABILITY_RECORDS: 
          //console.log(action.payload)  
                   return {
               ...state,
               deleteItemsAvailabilitySuccessMsg: action.payload.msg,
               ops: action.payload.ops,   
              };
           case GET_DELETE_ITEMS_AVAILABILITY_RECORDS_ERROR_MESSAGE:
            return {
                ...state,
                deleteItemsAvailabilityErrorMsg: action.payload.msg,
                ops: action.payload.ops,
              };
              case GET_BUSINESSES_FOR_ADDRESS:
                return { ...state, selectedConsumer: action.payload};
                case ADD_DELETE_BUSINESS:
                  return {
                      ...state,
                      sucessMsg: action.payload.msg,
                      ops: action.payload.ops
                      
                  };
              case ADD_DELETE_BUSINESS_ERROR:
                  return {
                      ...state,
                      errorMsg: action.payload.msg,
                      ops: action.payload.ops
                  };
                  case GET_CONSUMER_CART_DATA:
                return {
                      ...state,
                      consumerCartData:action.payload,
                      };
               case ADD_CONSUMER_TAGS:
                return {
                  ...state,
                  consumerList:{...state.consumerList,data:state.consumerList.data.map((eachConsumer)=>{
                    if(eachConsumer._id===action.payload?._id){
                      if(action.payload.consumerTagIds){
                        eachConsumer['consumerTagIds']=action.payload?.consumerTagIds;
                      }
                      return eachConsumer;
                    }
                    return eachConsumer
                  })}
                };             
              default:{
                  return state;
                    }
  
}

}