import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

function getSteps(costType, statusHistory, currentStatus, activeStep, preferredServiceMode, serviceMode) {
  let statusArray = [];
  if (costType.toLowerCase() === "fixed") {
    let requestedModeOfService = serviceMode ? serviceMode : preferredServiceMode;
    statusArray = ['Created'];
    statusHistory.forEach((status) => {
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "ACCEPTED") { statusArray.push('Accepted'); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "IN_PROGRESS") { statusArray.push('In_Progress'); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "CANCELLED") { statusArray.push('Cancelled'); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "REJECTED") { statusArray.push('Rejected'); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "OUT_FOR_DELIVERY") { statusArray.push('Out_for_Delivery'); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "DELIVERY_DONE") { statusArray.push('Delivery_Done'); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "READY_FOR_PICKUP") { statusArray.push('Ready_for_Pickup'); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "PICKUP_DONE") { statusArray.push('Pickup_Done'); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "COMPLETED") { statusArray.push('Completed'); }
    });

    if (!statusArray.includes('Accepted')) { statusArray.push('Accepted'); }
    if (!statusArray.includes('In_Progress')) { statusArray.push('In_Progress'); }
    if (requestedModeOfService === "DELIVERY") {
      if (!statusArray.includes('Out_for_Delivery')) { statusArray.push('Out_for_Delivery'); }
      if (!statusArray.includes('Delivery_Done')) { statusArray.push('Delivery_Done'); }
    }
    if (requestedModeOfService === "PICKUP") {
      if (!statusArray.includes('Ready_for_Pickup')) { statusArray.push('Ready_for_Pickup'); }
      if (!statusArray.includes('Pickup_Done')) { statusArray.push('Pickup_Done'); }
    }
    if (!statusArray.includes("Completed")) { statusArray.push('Completed'); }

  } else {
    statusArray = ['Initiated'];
    let requestedModeOfService = serviceMode ? serviceMode : preferredServiceMode;
    statusHistory.forEach(status => {
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "ACCEPTED") { statusArray.push("Accepted"); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "IN_PROGRESS") { statusArray.push("In_Progress"); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "QUOTED") { statusArray.push('Quoted'); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "QUOTE_DECLINED") { statusArray.push('Quote_Declined') }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "REJECTED_BY_CONSUMER") { statusArray.push('Rejected_by_Consumer') }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "REJECTED") { statusArray.push('Rejected'); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "CANCELLED") { statusArray.push('Cancelled') }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "OUT_FOR_DELIVERY") { statusArray.push("Out_for_Delivery"); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "DELIVERY_DONE") { statusArray.push("Delivery_Done"); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "READY_FOR_PICKUP") { statusArray.push("Ready_for_Pickup"); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "PICKUP_DONE") { statusArray.push("Pickup_Done"); }
      if (status.status && status.status !== null && status.status !== undefined && status.status.toUpperCase() === "COMPLETED") { statusArray.push("Completed"); }
    });

    if (!statusArray.includes('Accepted')) { statusArray.push('Accepted'); }
    if (!statusArray.includes('In_Progress')) { statusArray.push('In_Progress'); }
    if (requestedModeOfService === "DELIVERY") {
      if (!statusArray.includes('Out_for_Delivery')) { statusArray.push('Out_for_Delivery'); }
      if (!statusArray.includes('Delivery_Done')) { statusArray.push('Delivery_Done'); }
    }
    if (requestedModeOfService === "PICKUP") {
      if (!statusArray.includes('Ready_for_Pickup')) { statusArray.push('Ready_for_Pickup'); }
      if (!statusArray.includes('Pickup_Done')) { statusArray.push('Pickup_Done'); }
    }
    if (!statusArray.includes("Completed")) { statusArray.push('Completed'); }

  }
  return statusArray;
}

export default function HorizontalNonLinearAlternativeLabelStepper(props) {
  const classes = useStyles();
  const steps = getSteps(props.costType, props.serviceRequestDetails.statusHistory, props.serviceRequestDetails.currentStatus, props.activeStep, props.preferredServiceMode, props.serviceMode);

  function isStepComplete(step) {
    return props.completedSteps.has(step);
  }
  return (
    <div className={classes.root}>
      <Stepper alternativeLabel nonLinear activeStep={props.activeStep}>
        {steps.map((label, index) => {
          let actionDateTime = "";
          let updatedBy = "";
          let userType = ""
          let photo = ""
          let servicePartnerName = 'Dunzo';
          props.serviceRequestDetails && props.serviceRequestDetails.statusHistory &&
            props.serviceRequestDetails.statusHistory.forEach((status, indexNo) => {
              if(status.status && status.status !== null && status.status !== undefined) {
                if (label.toLowerCase() === status.status.toLowerCase() && index == indexNo) {
                  if(status.updatedBy.servicePartnerName && status.updatedBy.servicePartnerName !== null && status.updatedBy.servicePartnerName !== undefined && ['Borzo','Porter','Shadowfax',"Petpooja"].includes(status.updatedBy.servicePartnerName)) {
                    servicePartnerName = status.updatedBy.servicePartnerName;
                  } 
                  actionDateTime = moment(status.time).format('DD MMM YYYY hh:mm A');
                  updatedBy = `${status.updatedBy._id.firstName} ${status.updatedBy._id.lastName}`;
                  photo = status.updatedBy._id.photo;
                  userType = status.updatedBy.userType === 'consumer' ? "Consumer" : (status.updatedBy.userType === 'member') ? "Member" : (status.updatedBy.userType === 'servicePartner') ? "servicePartner" : (status.updatedBy.userType === 'serviceprovider') ? "Service Provider" : "Admin";
                  // servicePartnerName = status.updatedBy.servicePartnerName? status.updatedBy.servicePartnerName: 'Dunzo';
                }
              } else {
                actionDateTime = moment(status.time).format('DD MMM YYYY hh:mm A');
                updatedBy = `${status.updatedBy._id.firstName} ${status.updatedBy._id.lastName}`;
                photo = status.updatedBy._id.photo;
                userType = status.updatedBy.userType === 'consumer' ? "Consumer" : (status.updatedBy.userType === 'member') ? "Member" : (status.updatedBy.userType === 'servicePartner') ? "servicePartner" : (status.updatedBy.userType === 'serviceprovider') ? "Service Provider" : "Admin";
                servicePartnerName = status.updatedBy.servicePartnerName? status.updatedBy.servicePartnerName: 'Dunzo';
              }
              
            });
          const stepProps = {};
          const buttonProps = {};

          buttonProps.optional = <div style={{ display: "grid" }}>
            <Typography variant="caption">{actionDateTime}</Typography>
            {
              updatedBy ?
                <Typography variant="subtitle2" style={{ display: "flex", alignItems: "center" }}>
                  <Avatar alt="Action Taken By" src={photo} className={classes.small} /> &nbsp;
                  {userType === "servicePartner" ?  servicePartnerName : updatedBy}
                </Typography>
                : null
            }
            <Typography variant="caption">{userType}</Typography>
          </div>;

          return (
            <Step key={label} {...stepProps}>
              <StepButton
                disabled
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
