import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "INITIATED",
  "QUOTED",
  "QUOTE_DECLINED",
  "CREATED",
  "ACCEPTED",
  "REJECTED",
  "IN_PROGRESS",
  "CANCELLED",
  "COMPLETED",
  "OUT_FOR_DELIVERY",
  "REJECTED_BY_CONSUMER",
  "DELIVERY_DONE",
  "READY_FOR_PICKUP",
  "PICKUP_DONE",
];

export default function MultipleSelect(props) {
  const classes = useStyles();

  const handleChange = (event) => {
    props.handleFilterChange(event);
  };

  return (
    <div>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Current Status</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={props.currentStatus}
          name="currentStatus"
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={{ minHeight: "28px", height: "28px" }}
            >
              <Checkbox checked={props.currentStatus.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
